<template>

    <div>
        <div class="front-top-content text-center">
            <h3>Recupera la tua password</h3>
            <p>Inserisci l'indirizzo email con cui ti sei registrato, ti invieremo una mail con le istruzioni per reimpostare la tua password</p>
        </div>

        <div class="col d-sm-flex justify-content-center">
            <div class="card card-login">
                <div class="card-body">
                    <form v-on:submit="resetPassword" class="">
                        <div class="form-group">
                            <input
                                type="email"
                                class="form-control"
                                id="email"
                                ref="email"
                                required
                                name="email"
                                v-model="email"
                                placeholder="Email"
                            />
                        </div>
                        <div class="form-group">
                            <button class="btn btn-success btn-block" type="submit">Reimposta la password</button>
                        </div>
                    </form>
                    <p class="text-center"><router-link to="/login">Torna al login</router-link></p>
                    <p class="text-danger text-center" v-if="errorMsg">{{ errorMsg }}</p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import AuthDataService from "./AuthDataService";
import {required, email} from 'vuelidate/lib/validators'
import {HomepageByRole} from "@/helpers/HomepageByRole";

export default {
    name: "forgot-password",
    data() {
        return {
            email: "",
            errorMsg: null
        };
    },
    validations: {
        email: {
            required,
            email
        }
    },
    methods: {
        validateState(name) {
            const {$dirty, $error} = this.$v.item[name];
            return $dirty ? !$error : null;
        },
        resetPassword(e) {
            e.preventDefault();
            if (this.$v.$invalid) {
                this.submitted = false;
                this.errorMsg = 'Il campo email è obbligatorio e dev\'essere un\'email valida';
            } else {
                let data = {
                    email: this.email
                }
                AuthDataService.resetPassword(data)
                    .then(response => {
                        this.$store.state.loading = false;
                        // redirect to page response
                        this.$router.push('forgot-password-ok');
                    })
                    .catch(errors => {
                        this.$store.state.loading = false;
                        this.errorMsg = "Nessun utente corrisponde a questa email"
                    });
            }
        },
        focusEmail() {
            this.$refs.email.focus();
        },
    },
    mounted() {
        // if logged then redirect
        if (this.$store.state.isLoggedIn) {
            const uri = HomepageByRole(this.$store.state.user.roles[0]);
            this.$router.push(uri).catch(e => {});
        }
    }
}
</script>
